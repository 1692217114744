<template>
  <div class="main-container">
    <div class="section">
      <span class="text">CP3</span>
      <div class="wrapper">
        <div class="wrapper-2"><div class="pic"></div></div>
        <input class="Input" />
        <div class="pic-2"></div>
      </div>
    </div>
    <div class="section-2">
      <div class="section-3"><div class="img"></div></div>
      <div class="wrapper-3">
        <div class="section-4">
          <div class="box">
            <span class="text-2">Voice</span>
            <div class="pic-3"></div>
            <div class="section-5">
              <span class="text-3">3</span
              ><span class="text-4">improvements</span>
            </div>
            <div class="wrapper-4"><span class="text-5">1</span></div>
            <div class="wrapper-5"><span class="text-6">2</span></div>
          </div>
          <div class="section-6">
            <button class="Button"><span class="text-7">All</span></button>
            <div class="section-7"><div class="pic-4"></div></div>
            <div class="group">
              <div class="box-2"><div class="img-2"></div></div>
            </div>
            <div class="wrapper-6">
              <div class="wrapper-7"><div class="img-3"></div></div>
            </div>
          </div>
        </div>
        <div class="wrapper-8">
          <div class="group-2">
            <div class="flex-column-b">
              <div class="title"><span class="score">Score</span></div>
              <div class="frame">
                <div class="ellipse"></div>
                <span class="slash-8-10">8/10</span>
              </div>
            </div>
            <button class="frame-1">
              <span class="improve">Improve</span>
              <div class="vector"></div>
            </button>
          </div>
          <div class="card">
            <div class="title-2"><span class="summary">summary</span></div>
            <span class="lorem-ipsum"
              >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>
          </div>
          <div class="card-3">
            <div class="title-4"><span class="platform">Platform</span></div>
            <div class="flex-row-cc">
              <div class="layer"></div>
              <div class="solid-brands-linkedin"><div class="union"></div></div>
              <div class="outline-brands-instagram">
                <div class="icon"></div>
              </div>
            </div>
            <div class="flex-row">
              <div class="frame-5">
                <div class="ellipse-6"></div>
                <span class="slash-9-10">9/10</span>
              </div>
              <div class="frame-7">
                <div class="ellipse-8"></div>
                <span class="slash-5-10">5/10</span>
              </div>
              <div class="frame-9">
                <div class="ellipse-a"></div>
                <span class="dot-slash-9-5-10">9.5/10</span>
              </div>
            </div>
            <div class="flex-row-b">
              <button class="frame-c">
                <span class="improve-d">Improve</span>
                <div class="vector-e"></div></button
              ><button class="Button-4">
                <span class="text-12">Improve</span>
                <div class="img-7"></div></button
              ><button class="Button-5">
                <span class="text-13">Improve</span>
                <div class="img-8"></div>
              </button>
            </div>
          </div>
          <div class="section-b"><span class="text-14">Overall</span></div>
        </div>
        <div class="img-9"></div>
        <div class="wrapper-b">
          <div class="group-a">
            <div class="wrapper-c"><span class="text-15">Voice</span></div>
            <div class="section-c">
              <div class="group-b">
                <span class="text-16">Ambitious<br /></span
                ><span class="text-17">Confident<br />Aspirational</span>
              </div>
            </div>
          </div>
          <div class="group-c">
            <div class="wrapper-d"><span class="text-18">Score</span></div>
            <div class="wrapper-e">
              <div class="img-a"></div>
              <span class="text-19">9/10</span>
            </div>
          </div>
          <div class="wrapper-f">
            <div class="section-d"><span class="text-1a">Ambitious</span></div>
            <span class="text-1b"
              >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>
          </div>
          <div class="section-e">
            <div class="group-d"><span class="text-1c">Platform</span></div>
            <div class="section-f">
              <div class="pic-b"></div>
              <div class="group-e"><div class="pic-c"></div></div>
              <div class="section-10"><div class="pic-d"></div></div>
            </div>
            <div class="group-f">
              <div class="wrapper-10">
                <div class="pic-e"></div>
                <span class="text-1d">9/10</span>
              </div>
              <div class="group-10">
                <div class="pic-f"></div>
                <span class="text-1e">7.5/10</span>
              </div>
              <div class="section-11">
                <div class="pic-10"></div>
                <span class="text-1f">9.5/10</span>
              </div>
            </div>
            <div class="wrapper-11">
              <button class="Button-6">
                <span class="text-20">Improve</span>
                <div class="pic-11"></div></button
              ><button class="Button-7">
                <span class="text-21">Improve</span>
                <div class="img-b"></div></button
              ><button class="Button-8">
                <span class="text-22">Improve</span>
                <div class="img-c"></div>
              </button>
            </div>
          </div>
          <div class="section-12"><span class="text-23">Values</span></div>
        </div>
        <div class="img-d"></div>
        <div class="box-6">
          <div class="wrapper-12">
            <div class="pic-12"></div>
            <button class="Button-9">
              <span class="text-24">Improve</span>
              <div class="pic-13"></div>
            </button>
          </div>
          <div class="section-13">
            <div class="box-7">
              <span class="text-25">Frequently used words</span>
            </div>
            <div class="group-11">
              <div class="group-12">
                <span class="text-26">Superior<br /></span
                ><span class="text-27">Discover<br />Hybrid</span>
              </div>
              <div class="group-13">
                <div class="img-e"></div>
                <span class="text-28">6/10</span>
              </div>
            </div>
          </div>
          <div class="wrapper-13">
            <div class="group-14">
              <span class="text-29">Alternatives</span>
            </div>
            <div class="wrapper-14">
              <div class="wrapper-15">
                <span class="text-2a">More advanced </span
                ><span class="text-2b">- Higher-up - Worthier</span>
              </div>
            </div>
            <div class="wrapper-16">
              <span class="text-2c">off-brand</span>
              <div class="section-14">
                <span class="text-2d">on-brand</span>
                <div class="img-f"></div>
                <div class="img-10"></div>
              </div>
            </div>
          </div>
          <span class="text-2e">Campaign</span>
        </div>
        <div class="pic-14"></div>
      </div>
      <div class="box-8">
        <div class="box-9"><div class="pic-15"></div></div>
      </div>
      <div class="wrapper-17"><div class="img-11"></div></div>
      <div class="group-15"><div class="pic-16"></div></div>
      <div class="box-a"><div class="pic-17"></div></div>
      <div class="box-b"><div class="img-12"></div></div>
      <div class="section-15"><div class="img-13"></div></div>
      <div class="section-16"><div class="img-14"></div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VoiceView',
  data() {
    return {
      sidebarIcons: [
        { src: 'icon1.png', alt: 'Icon 1' },
        { src: 'icon2.png', alt: 'Icon 2' },
        { src: 'icon3.png', alt: 'Icon 3' },
        { src: 'icon4.png', alt: 'Icon 4' },
        { src: 'icon5.png', alt: 'Icon 5' }
      ],
      userProfileImage: 'profile.png',
      overallScore: 6.5,
      contentWarnings: 3,
      metrics: {
        overallTone: {
          title: 'OVERALL TONE',
          value: 'Confident',
          description: 'Maintain your professional tone while adding more friendly and innovative elements.'
        },
        formality: {
          title: 'FORMALITY',
          value: '8/10',
          description: 'Maintain your professional tone while adding more friendly and innovative elements.'
        },
        mainEmotion: {
          title: 'MAIN EMOTION',
          value: 'Trust',
          description: 'Maintain your professional tone while adding more friendly and innovative elements.'
        }
      },
      platformToneData: {
        // Sankey chart data (example format, replace with actual data)
        nodes: [
          { id: 'Web', label: 'Web' },
          { id: 'LinkedIn', label: 'LinkedIn' },
          { id: 'Instagram', label: 'Instagram' }
        ],
        links: [
          { source: 'Web', target: 'LinkedIn', value: 3 },
          { source: 'LinkedIn', target: 'Instagram', value: 5 }
        ]
      },
      toneData: {
        // Bar chart data (example format, replace with actual data)
        labels: ['Confident', 'Friendly', 'Formal', 'Optimistic'],
        values: [85, 47, 65, 23]
      }
  }
}
}
</script>