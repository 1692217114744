<template>
  <div class="early-access-container">
    <div class="early-access-form">
      
      <form @submit.prevent="handleSubmit">
        <div class="input-group">
          <input
            type="email"
            v-model="email"
            placeholder="Write your email to get access"
            :class="{ 'error': errorMessage }"
          />
          <span class="error-message" v-if="errorMessage">{{ errorMessage }}</span>
        </div>
        <button type="submit" :disabled="!isValidEmail">Request Access</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import apiConfig from '@/config/api';

export default {
  name: 'EarlyAccess',
  data() {
    return {
      email: '',
      errorMessage: ''
    };
  },
  computed: {
    isValidEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    }
  },
  methods: {
    async handleSubmit() {
      this.errorMessage = '';
      
      // Check for restricted domains
      const restrictedDomains = ['hotmail', 'yahoo', 'gmail', 'mac', 'aol'];
      const domain = this.email.split('@')[1].split('.')[0].toLowerCase();
      
      if (restrictedDomains.includes(domain)) {
        this.errorMessage = 'This email domain is not allowed';
        return;
      }

      try {
        const response = await axios.post(`${apiConfig.baseURL}${apiConfig.endpoints.EarlyAccess}`, {
          email: this.email
        });

        if (response.data.exists) {
          // Redirect to user-login if user exists
          this.$router.push('/user-login');
        } else {
          // Show success message
          this.$router.push('/thank-you');
        }
      } catch (error) {
        this.errorMessage = error.response?.data?.message || 'We already have a record for this email';
      }
    }
  }
};
</script>
