<template>
  <div class="header">
    <router-link to="/login" class="login-page">Login</router-link>
  </div>
  <div class="signup-container">
    <h2>Sign Up</h2>
    <form @submit.prevent="handleSubmit">
      <input v-model="username" type="text" placeholder="Username" required />
      <input v-model="email" type="email" placeholder="Email" required />
      <input v-model="password" type="password" placeholder="Password" required />
      <select v-model="tier" required @change="updatePriceInfo">
        <option value="friend">Friend ($1/month)</option>
        <option value="entry">Entry ($199/month)</option>
        <option value="premium">Premium ($499/month)</option>
        <option value="enterprise">Enterprise ($999/month)</option>
      </select>
      <button 
        type="submit" 
        :disabled="isLoading"
      >
        {{ isFreeTier ? 'Sign Up' : 'Proceed to Payment' }}
      </button>
    </form>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    <div v-if="isLoading" class="loading-message">Processing...</div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import api from '@/config/api';

export default {
  name: "UserSignup",
  data() {
    return {
      username: '',
      email: '',
      password: '',
      tier: 'friend',
      role: 'user',
      errorMessage: '',
      isLoading: false,
      stripePromise: null,
      stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY || 'pk_test_51QO0YQFZc6nohvKMf2qB4P0244arSmU46EA3x284FpLbPZcV3OmunawEvGW7PqH2jTLv2rfVZURHQn3VtuMQLER900jvheEWhN',
      priceIds: {
        friend: 'price_friend',
        entry: 'price_entry',
        premium: 'price_premium',
        enterprise: 'price_enterprise'
      },
      tierPrices: {
        friend: 1,
        entry: 199,
        premium: 499,
        enterprise: 999
      }
    };
  },
  computed: {
    isFreeTier() {
      return this.tier === 'friend';
    }
  },
  async created() {
    // Initialize Stripe with the publishable key from config
    this.stripePromise = loadStripe(this.stripePublishableKey);
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true;
      this.errorMessage = '';
      
      const userData = {
        username: this.username,
        email: this.email,
        password: this.password,
        subscription_tier: this.tier,
        role: this.role
      };

      try {
        if (this.isFreeTier) {
          // Handle free tier registration directly
          const response = await axios.post(`${api.baseURL}${api.endpoints.signup}`, userData);
          if (response.data && response.status === 200) {
            this.$router.push('/login');
          } else {
            throw new Error('Registration failed. Please try again.');
          }
        } else {
          // For paid tiers, create checkout session
          const response = await axios.post(`${api.baseURL}${api.endpoints.createCheckoutSession}`, {
            ...userData,
            priceId: this.priceIds[this.tier]
          });

          if (!response.data || !response.data.sessionId) {
            throw new Error('Failed to create checkout session');
          }

          const { sessionId } = response.data;
          const stripe = await this.stripePromise;
          
          // Redirect to Stripe checkout
          const { error } = await stripe.redirectToCheckout({
            sessionId
          });

          if (error) {
            throw new Error(error.message);
          }
        }
      } catch (error) {
        this.errorMessage = error.response?.data?.message || error.message || 'An error occurred during signup';
        console.error('Signup error:', error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style>
/* Styles moved to CP3.css */
</style>
