<template>
  <div class="main-container">
    <div class="group">
      <span class="text">CP3</span>
      <div class="group-2">
        <div class="section"><div class="img"></div></div>
        <div class="img-2"></div>
      </div>
    </div>
    <div class="wrapper">
      <div class="group-3"><div class="pic"></div></div>
      <div class="section-2">
        <div class="group-4">
          <div class="group-5">
            <span class="text-2">Vision</span>
            <div class="img-3"></div>
            <div class="box">
              <span class="text-3">5</span
              ><span class="text-4">improvements</span>
            </div>
            <button class="Button"><span class="text-5">5</span></button>
          </div>
          <div class="group-6">
            <button class="Button-2"><span class="text-6">All</span></button>
            <div class="section-3"><div class="img-4"></div></div>
            <div class="box-2">
              <div class="section-4"><div class="img-5"></div></div>
            </div>
            <div class="box-3">
              <div class="wrapper-2"><div class="img-6"></div></div>
            </div>
          </div>
        </div>
        <div class="group-7">
          <div class="group-8">
            <div class="box-4"><span class="text-7">Score</span></div>
            <div class="group-9">
              <div class="img-7"></div>
              <span class="text-8">7/10</span>
            </div>
          </div>
          <div class="section-5">
            <div class="pic-2"></div>
            <button class="Button-3">
              <span class="improve">Improve</span>
              <div class="vector"></div>
            </button>
          </div>
          <div class="card">
            <div class="title"><span class="summary">summary</span></div>
            <span class="lorem-ipsum"
              >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>
          </div>
          <div class="card-1">
            <div class="title-2"><span class="platform">Platform</span></div>
            <div class="flex-row-af">
              <div class="layer"></div>
              <div class="solid-brands-linkedin"><div class="union"></div></div>
              <div class="outline-brands-instagram">
                <div class="icon"></div>
              </div>
            </div>
            <div class="flex-row-bbe">
              <div class="frame">
                <div class="ellipse"></div>
                <span class="slash-9-10">9/10</span>
              </div>
              <div class="frame-3">
                <div class="ellipse-4"></div>
                <span class="slash-6-10">6/10</span>
              </div>
              <div class="frame-5">
                <div class="ellipse-6"></div>
                <span class="slash-6-10-7">6/10</span>
              </div>
            </div>
          </div>
          <div class="title-8"><span class="overall">Overall</span></div>
        </div>
        <div class="line"></div>
        <div class="flex-row-fe">
          <div class="card-9">
            <div class="title-a"><span class="style">Style</span></div>
            <div class="frame-b">
              <div class="casual-sport-streetwear">
                <span class="casual">Casual<br /></span
                ><span class="sport-streetwear">Sport<br />Streetwear</span>
              </div>
            </div>
          </div>
          <div class="card-c">
            <div class="title-d"><span class="score">Score</span></div>
            <div class="frame-e">
              <div class="ellipse-f"></div>
              <span class="fraction-8-10">8/10</span>
            </div>
          </div>
          <div class="frame-10">
            <span class="art-direction-review">Art Direction review</span>
            <div class="flex-row-fb">
              <div class="rectangle"></div>
              <div class="image"></div>
              <div class="image-11"></div>
              <button class="frame-12">
                <span class="male-age-29">male, age 29</span></button
              ><button class="frame-13">
                <span class="sneakers">sneakers</span>
              </button>
            </div>
            <span class="casual-14">Casual</span>
            <div class="group">
              <span class="off-brand">off-brand</span>
              <div class="flex-column-adf">
                <span class="on-brand">on-brand</span>
                <div class="line-15"></div>
                <div class="group-16"></div>
              </div>
            </div>
          </div>
          <div class="card-17">
            <div class="title-18">
              <span class="platform-19">Platform</span>
            </div>
            <div class="frame-1a">
              <div class="frame-1b"><div class="layer-1c"></div></div>
              <div class="group-1d">
                <span class="off-brand-1e">off-brand</span>
                <div class="flex-column">
                  <span class="on-brand-1f">on-brand</span>
                  <div class="line-20"></div>
                  <div class="group-21"></div>
                </div>
              </div>
            </div>
            <div class="frame-22">
              <div class="frame-23">
                <div class="solid-brands-linkedin-24">
                  <div class="union-25"></div>
                </div>
              </div>
              <div class="group-26">
                <span class="off-brand-27">off-brand</span>
                <div class="flex-column-de">
                  <span class="on-brand-28">on-brand</span>
                  <div class="line-29"></div>
                  <div class="group-2a"></div>
                </div>
              </div>
            </div>
            <div class="frame-2b">
              <div class="frame-2c">
                <div class="outline-brands-instagram-2d">
                  <div class="icon-2e"></div>
                </div>
              </div>
              <div class="group-2f">
                <span class="off-brand-30">off-brand</span>
                <div class="flex-column-31">
                  <span class="on-brand-32">on-brand</span>
                  <div class="line-33"></div>
                  <div class="group-34"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="frame-35">
            <span class="style-combined-with">style combined with</span>
            <div class="frame-36">
              <button class="frame-37">
                <div class="rectangle-38"></div>
                <span class="runningclub">runningclub</span></button
              ><button class="frame-39">
                <div class="rectangle-3a"></div>
                <span class="sole">sole</span></button
              ><button class="frame-3b">
                <div class="rectangle-3c"></div>
                <span class="sneaker">sneaker</span></button
              ><button class="frame-3d">
                <div class="rectangle-3e"></div>
                <span class="sport">sport</span>
              </button>
            </div>
          </div>
          <div class="card-3f">
            <div class="title-40"><span class="visual">Visual</span></div>
            <div class="frame-41">
              <div class="ellipse-42"></div>
              <span class="text-29">6/10</span>
            </div>
            <div class="frame-43">
              <span class="text-2a">12</span
              ><button class="frame-44"><div class="vector-45"></div></button>
            </div>
          </div>
          <div class="card-46">
            <div class="title-47"><span class="image-48">Image</span></div>
            <div class="frame-49">
              <div class="ellipse-4a"></div>
              <span class="text-2c">8/10</span>
            </div>
            <div class="frame-4b">
              <span class="number-36">36</span>
              <div class="frame-4c"><div class="vector-4d"></div></div>
            </div>
          </div>
          <div class="card-4e">
            <div class="title-4f"><span class="video">Video</span></div>
            <div class="frame-50">
              <div class="ellipse-51"></div>
              <span class="slash-7-10">7/10</span>
            </div>
            <div class="frame-52">
              <span class="number-24">24</span>
              <div class="frame-53"><div class="vector-54"></div></div>
            </div>
          </div>
          <div class="card-55">
            <div class="title-56">
              <span class="popular-objects">Popular objects</span>
            </div>
            <div class="frame-57">
              <div class="sneakers-jackets-accessories">
                <span class="sneakers-58">Sneakers<br /></span
                ><span class="jackets-accessories"
                  >Jackets<br />Accessories</span
                >
              </div>
            </div>
          </div>
          <div class="card-59">
            <div class="layer-5a"></div>
            <button class="button">
              <span class="improve-5b">Improve</span>
              <div class="vector-5c"></div>
            </button>
          </div>
        </div>
      </div>
      <div class="rectangle-5d">
        <div class="outline-media-microphone"><div class="icon-5e"></div></div>
      </div>
      <div class="rectangle-5f"><div class="icon-60"></div></div>
      <div class="rectangle-61"><div class="icon-62"></div></div>
      <div class="rectangle-63"><div class="icon-64"></div></div>
      <div class="rectangle-65"><div class="icon-66"></div></div>
      <div class="rectangle-67"><div class="icon-68"></div></div>
      <div class="rectangle-69"><div class="icon-6a"></div></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VisionView',
  data() {
    return {
      sidebarIcons: [
        { src: 'icon1.png', alt: 'Icon 1' },
        { src: 'icon2.png', alt: 'Icon 2' },
        { src: 'icon3.png', alt: 'Icon 3' },
        { src: 'icon4.png', alt: 'Icon 4' },
        { src: 'icon5.png', alt: 'Icon 5' }
      ],
      userProfileImage: 'profile.png',
      overallScore: 6.5,
      popularTags: ['runningclub', 'sole', 'sneaker', 'sport', 'performance', 'shoes', 'trail', 'urban', 'hotdrops', 'running', 'sustainable', 'comfort', 'nature'],
      contentWarnings: 4,
      metrics: {
        popularStyle: {
          title: 'POPULAR STYLE',
          value: 'Casual'
        },
        popularObject: {
          title: 'POPULAR OBJECT',
          value: 'Sneaker'
        },
        logoAppearance: {
          title: 'LOGO APPEARANCE',
          value: 54
        },
        celebrities: {
          title: 'CELEBRITIES',
          value: 8
        }
      },
      artImages: [
        { src: 'art1.png', alt: 'Art Direction 1', caption: 'Casual style' },
        { src: 'art2.png', alt: 'Art Direction 2', caption: 'Sporty style' },
        { src: 'art3.png', alt: 'Art Direction 3', caption: 'Techwear style' }
      ],
      styleBreakdownData: {
        labels: ['Casual', 'Sporty', 'Techwear', 'Other'],
        values: [56, 14, 21, 9]
      },
      audienceAgeRange: '21-35',
      audienceGender: 'Male',
      audienceTone: 'Confident',
      targetAudience: [
        { id: 1, image: 'audience1.png', name: 'Audience 1' },
        { id: 2, image: 'audience2.png', name: 'Audience 2' },
        { id: 3, image: 'audience3.png', name: 'Audience 3' }
      ],
      emotionData: {
        labels: ['Happy', 'Confident', 'Calm', 'Focused'],
        values: [56.7, 21, 14, 1.3]
      }
    }
  }
});
</script>
