<template>
  <div class="campaign-dashboard">
    <div class="sidebar">
      <button v-for="(icon, index) in sidebarIcons" :key="index" class="sidebar-icon">
        <img :src="icon.src" :alt="icon.alt" />
      </button>
    </div>
    <div class="content">
      <div class="header">
        <h1>Campaign Title</h1>
        <div class="profile">
          <img :src="userProfileImage" alt="User Profile" class="profile-pic" />
        </div>
      </div>
      <div class="campaign-details">
        <div class="detail-card" v-for="(detail, index) in campaignDetails" :key="index">
          <h2>{{ detail.title }}</h2>
          <p>{{ detail.content }}</p>
        </div>
      </div>
      <div class="storyboard">
        <h2>Storyboard</h2>
        <div class="storyboard-images">
          <div v-for="(image, index) in storyboardImages" :key="index" class="storyboard-image">
            <img :src="image.src" :alt="image.alt" />
            <p>{{ image.caption }}</p>
          </div>
        </div>
      </div>
    </div><SideBar />
  </div>


</template>
<script>
import SideBar from '@/components/SideBar.vue'
export default {
  name: 'CampaignView',
  components: {
    SideBar
  },
  
  data() {
    return {
      sidebarIcons: [
        { src: 'icon1.png', alt: 'Icon 1' },
        { src: 'icon2.png', alt: 'Icon 2' },
        { src: 'icon3.png', alt: 'Icon 3' },
        { src: 'icon4.png', alt: 'Icon 4' },
        { src: 'icon5.png', alt: 'Icon 5' }
      ],
      userProfileImage: 'profile.png',
      campaignDetails: [
        { title: 'Objective', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        { title: 'Key Message', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        { title: 'Audience', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        { title: 'Caption', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        { title: 'Platforms', content: 'LinkedIn, Instagram' },
        { title: 'Hashtags', content: '#' },
        { title: 'Assets', content: '#' },
        { title: 'Timeline', content: '#' }
      ],
      storyboardImages: [
        { src: 'storyboard1.png', alt: 'Storyboard Image 1', caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        { src: 'storyboard2.png', alt: 'Storyboard Image 2', caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
        { src: 'storyboard3.png', alt: 'Storyboard Image 3', caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' }
      ]
    }
  }
}
</script>

