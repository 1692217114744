<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import './assets/CP3.css';

export default {
  name: 'App'
};
</script>