<template>
  <div id="dashboard">
    <!-- Add loading overlay -->
    <div v-if="isSubmitting" class="loading-overlay">
      <div class="loading-content">
        <div class="spinner"></div>
        <div class="loading-text">Analyzing URL...</div>
      </div>
    </div>

    <!-- Sidebar -->
    <aside class="sidebar">
      <a href="/dashboard" class="sidebar-link">
        <img src="../assets/images/home_th.svg" class="home-link"></a>
      <a href="/voice" class="sidebar-link">
        <img src="../assets/images/voice_th.svg" class="voice-link"></a>
      <a href="/vision" class="sidebar-link">
        <img src="../assets/images/vision_th.svg" class="vision-link"></a>
      <a href="/trends" class="sidebar-link">
        <img src="../assets/images/trends_th.svg" class="trends-link"></a>
      <a href="/advice" class="sidebar-link">
        <img src="../assets/images/advice_th.svg" class="advice-link"></a>
      <a href="/settings" class="sidebar-link">
        <img src="../assets/images/settings_th.svg" class="settings-link"></a>
    </aside>

    <!-- Header Section -->
    <header class="header">
      <h1>CP3 Dashboard 
        <span v-if="user" class="analyzing-url">
          <span v-if="user.username">{{ user.username }}</span>
          <span v-if="user.url"> - Analyzing: {{ formatUrl(user.url) }}</span>
        </span>
        <span v-else-if="loading" class="loading-text">Loading user details...</span>
      </h1>
      
      
      <!-- URL submission form moved after user info -->
      <div class="url-submit-container">
        <form @submit.prevent="handleUrlSubmit" class="url-form">
          <span class="url-prefix">https://</span>
          <input 
            type="text"
            v-model="newUrl" 
            placeholder="Analyze URL"
            required
            pattern="^[^/\s]+\.[^/\s]+.*$"
            class="url-input"
          >
          <button 
            type="submit" 
            :disabled="isSubmitting" 
            class="arrow-button"
          >
            <span v-if="isSubmitting" class="spinner"></span>
            <span v-else>➔</span>
          </button>
        </form>
      </div>
    <div class="user-info">
      <img src="../assets/images/profile_th.svg" alt="profile" class="user-avatar" />
      <button @click="handleLogout" class="logout-btn">Logout</button>
    </div>
  </header>

    <!-- Main Content -->
    <main class="main-content">
      <div class="charts-container">
        <!-- Tone Analysis -->
        <div class="chart-section">
          <h2>Tone Analysis</h2>
          <div v-if="chartStates.tone.loading" class="loading">Loading tone analysis...</div>
          <div v-else-if="chartStates.tone.error" class="error-message">
            {{ chartStates.tone.error }}
          </div>
          <div v-else-if="data.tone_analysis" class="chart-container">
            <RadarChart 
              v-if="toneChartData"
              :key="`tone-${JSON.stringify(toneChartData)}`"
              :chartData="toneChartData"
              :options="toneChartOptions"
              class="chart"
            />
            <div v-else class="no-data">
              Unable to display tone chart
            </div>
          </div>
        </div>

       

        <!-- Emotion Analysis -->
        <div class="chart-section">
          <h2>Emotion Analysis</h2>
          <div v-if="chartStates.emotion.loading" class="loading">Loading emotion analysis...</div>
          <div v-else-if="chartStates.emotion.error" class="error-message">
            {{ chartStates.emotion.error }}
          </div>
          <div v-else-if="data.emotion_analysis" class="chart-container">
            <DoughnutChart 
              v-if="emotionChartData"
              :key="`emotion-${JSON.stringify(emotionChartData)}`"
              :chartData="emotionChartData"
              :options="emotionChartOptions"
            />
            <div v-else class="no-data">
              Unable to display emotion chart
            </div>
          </div>
        </div>

        <!-- Object Analysis -->
        <div class="chart-section">
          <h2>Object Analysis</h2>
          <div v-if="chartStates.object.loading" class="loading">Loading object analysis...</div>
          <div v-else-if="chartStates.object.error" class="error-message">
            {{ chartStates.object.error }}
          </div>
          <div v-else-if="data.object_analysis">
            <BarChart 
              v-if="objectChartData"
              :key="`object-${JSON.stringify(objectChartData)}`"
              :chartData="objectChartData"
              :options="defaultBarOptions"
              @error="handleChartError('object', $event)"
            />
            <div v-else class="no-data">
              Unable to display object chart
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch, nextTick } from 'vue';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  Title, 
  Tooltip, 
  Legend, 
  RadialLinearScale, 
  PointElement, 
  LineElement,
  Filler,
  RadarController,
  ArcElement,
  BarController,
  DoughnutController
} from 'chart.js';
import { BarChart, RadarChart, DoughnutChart } from 'vue-chart-3';
import axios from 'axios';
import apiConfig from '../config/api.js';
import { useRouter, useRoute } from 'vue-router';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  RadarController,
  ArcElement,
  BarController,
  DoughnutController
);

export default {
  name: 'DashboardUser',
  components: {
    BarChart,
    RadarChart,
    DoughnutChart,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const data = ref({
      tone_analysis: null,
      emotion_analysis: null,
      object_analysis: null,
      style_analysis: null,
      audience_analysis: null
    });
    const error = ref(null);
    const loading = ref(false);
    const lastAnalyzedUrl = ref(null);
    const user = ref(null);
    const currentUrl = ref(null);

    const chartStates = ref({
      tone: { loading: false, error: null },
      emotion: { loading: false, error: null },
      object: { loading: false, error: null }
    });

    const newUrl = ref('');
    const isSubmitting = ref(false);

    // Tone Chart Data
    const toneChartData = computed(() => {
      if (!data.value.tone_analysis?.data) {
        console.log('No tone data available');
        return null;
      }

      const toneData = data.value.tone_analysis.data;
      console.log('Processing tone data:', toneData);

      const chartData = {
        labels: Object.keys(toneData),
        datasets: [{
          label: 'Tone Analysis',
          data: Object.values(toneData),
          fill: true,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          pointBackgroundColor: 'rgb(54, 162, 235)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(54, 162, 235)',
          borderWidth: 2
        }]
      };
      console.log('Generated chart data:', chartData);
      return chartData;
    });

    // Add a watcher to debug data changes
    watch(() => data.value.tone_analysis, (newVal) => {
      console.log('Tone analysis data changed:', newVal);
      if (newVal?.data) {
        console.log('New chart data will be:', {
          labels: Object.keys(newVal.data),
          values: Object.values(newVal.data)
        });
      }
    }, { deep: true });

    // Emotion Chart Data
    const emotionChartData = computed(() => {
      if (!data.value.emotion_analysis?.data) {
        console.log('No emotion data available');
        return null;
      }

      const emotionData = data.value.emotion_analysis.data;
      const emotionScores = emotionData.emotion_scores;
      console.log('Processing emotion data:', emotionScores);

      // Fixed order of emotions for consistent display
      const fixedEmotions = [
        'Friendly', 'Relaxed', 'Inspiring', 'Enthusiastic', 
        'Optimistic', 'Professional', 'Analytical', 'Cautious', 'Empathetic'
      ];

      // Create arrays for labels and data in fixed order
      const orderedData = fixedEmotions.map(emotion => ({
        emotion,
        score: emotionScores[emotion] || 0
      }));

      // Generate colors based on scores (darker = higher score)
      const generateColor = (score) => `rgba(255, 99, 132, ${score})`;

      return {
        labels: orderedData.map(d => d.emotion),
        datasets: [{
          label: 'Emotion Analysis',
          data: orderedData.map(d => d.score),
          backgroundColor: orderedData.map(d => generateColor(d.score)),
          borderColor: 'white',
          borderWidth: 2,
          hoverBorderColor: 'white',
          hoverBorderWidth: 3,
        }]
      };
    });

    // Object Chart Data
    const objectChartData = computed(() => {
      if (!data.value.object_analysis?.data) {
        console.log('No object data available');
        return null;
      }

      const objectData = data.value.object_analysis.data;
      console.log('Processing object data:', objectData);

      const chartData = {
        labels: objectData.labels,
        datasets: [{
          label: 'Object Analysis',
          data: objectData.scores,
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(255, 206, 86, 0.5)',
            'rgba(75, 192, 192, 0.5)',
            'rgba(153, 102, 255, 0.5)',
          ]
        }]
      };
      console.log('Generated object chart data:', chartData);
      return chartData;
    });

    // Style Chart Data
    const styleChartData = computed(() => {
      if (!data.value.style_analysis?.data) return null;
      const styleData = data.value.style_analysis.data;
      return {
        labels: Object.keys(styleData),
        datasets: [{
          label: 'Style Analysis',
          data: Object.values(styleData),
          backgroundColor: [
            'rgba(255, 159, 64, 0.5)',
            'rgba(255, 99, 132, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(255, 206, 86, 0.5)',
            'rgba(75, 192, 192, 0.5)',
          ]
        }]
      };
    });

    // Audience Chart Data
    const audienceChartData = computed(() => {
      if (!data.value.audience_analysis?.data) return null;
      const audienceData = data.value.audience_analysis.data;
      return {
        labels: Object.keys(audienceData),
        datasets: [{
          label: 'Audience Analysis',
          data: Object.values(audienceData),
          backgroundColor: [
            'rgba(153, 102, 255, 0.5)',
            'rgba(255, 159, 64, 0.5)',
            'rgba(255, 99, 132, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(255, 206, 86, 0.5)',
          ]
        }]
      };
    });

    // Chart Options
    const toneChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        r: {
          beginAtZero: true,
          min: 0,
          max: 1,
          ticks: {
            stepSize: 0.2
          },
          grid: {
            circular: true
          },
          pointLabels: {
            font: {
              size: 12
            }
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'top'
        }
      }
    };

    const emotionChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: '50%',
      plugins: {
        legend: {
          display: true,
          position: 'right',
          labels: {
            generateLabels: (chart) => {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map((label, i) => ({
                  text: `${label}: ${(data.datasets[0].data[i] * 100).toFixed(0)}%`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  strokeStyle: data.datasets[0].borderColor,
                  lineWidth: data.datasets[0].borderWidth,
                  hidden: false,
                  index: i
                }));
              }
              return [];
            }
          }
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = context.label || '';
              const value = context.raw || 0;
              return `${label}: ${(value * 100).toFixed(0)}%`;
            }
          }
        }
      }
    };

    const defaultBarOptions = {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
          max: 1,
          title: {
            display: true,
            text: 'Score'
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      }
    };

    // Error handling for charts
    const handleChartError = (chartType, error) => {
      console.error(`Error rendering ${chartType} chart:`, error);
      console.error('Chart data at time of error:', data.value[`${chartType}_analysis`]);
      chartStates.value[chartType].error = `Error displaying ${chartType} chart: ${error.message}`;
    };

    // Initialize charts using composition API
    const { radarChartProps: toneChartProps } = {
      chartData: toneChartData,
      options: toneChartOptions
    };

    const { barChartProps: emotionChartProps } = {
      chartData: emotionChartData,
      options: emotionChartOptions
    };

    const { barChartProps: objectChartProps } = {
      chartData: objectChartData,
      options: defaultBarOptions
    };

    const { barChartProps: styleChartProps } = {
      chartData: styleChartData,
      options: defaultBarOptions
    };

    const { barChartProps: audienceChartProps } = {
      chartData: audienceChartData,
      options: defaultBarOptions
    };

    // Methods
    async function fetchDataFromBackend(url) {
      if (!url) return;
      
      console.log('Starting data fetch for:', url);
      
      // Set loading states
      Object.keys(chartStates.value).forEach(key => {
        chartStates.value[key].loading = true;
        chartStates.value[key].error = null;
      });
      
      try {
        // Fetch all analyses in parallel
        const results = await Promise.allSettled([
          fetchToneAnalysis(url),
          fetchEmotionAnalysis(url),
          fetchObjectAnalysis(url)
        ]);

        console.log('Fetch results:', results);

        // Force chart updates
        nextTick(() => {
          const charts = document.querySelectorAll('canvas');
          charts.forEach(canvas => {
            const chart = ChartJS.getChart(canvas);
            if (chart) {
              console.log('Updating chart:', chart.id);
              chart.update();
            }
          });
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // Reset loading states
        Object.keys(chartStates.value).forEach(key => {
          chartStates.value[key].loading = false;
        });
      }
    }

    const getHeaders = () => {
      const token = localStorage.getItem('userToken');
      if (!token) {
        throw new Error('No access token found');
      }
      return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      };
    };

    async function fetchToneAnalysis(url) {
      chartStates.value.tone.loading = true;
      chartStates.value.tone.error = null;
      try {
        const headers = getHeaders();
        const response = await axios.get(
          `${apiConfig.baseURL}/app/charts/tone-analysis/${encodeURIComponent(url)}`,
          { headers }
        );
        console.log('Raw tone analysis response:', response);
        console.log('Tone analysis data:', response.data);
        
        if (!response.data?.data) {
          throw new Error('Invalid response format');
        }
        
        data.value.tone_analysis = response.data;
      } catch (error) {
        console.error('Error fetching tone analysis:', error);
        chartStates.value.tone.error = error.response?.data?.error || 'Failed to load tone analysis';
      } finally {
        chartStates.value.tone.loading = false;
      }
    }

    async function fetchEmotionAnalysis(url) {
      chartStates.value.emotion.loading = true;
      chartStates.value.emotion.error = null;
      try {
        const headers = getHeaders();
        const response = await axios.get(
          `${apiConfig.baseURL}/app/charts/emotion-analysis/${encodeURIComponent(url)}`,
          { headers }
        );
        data.value.emotion_analysis = response.data;
      } catch (error) {
        console.error('Error fetching emotion analysis:', error);
        chartStates.value.emotion.error = error.response?.data?.error || 'Failed to load emotion analysis';
        if (error.message === 'No access token found') {
          router.push('/user-login');
        }
      } finally {
        chartStates.value.emotion.loading = false;
      }
    }

    async function fetchObjectAnalysis(url) {
      chartStates.value.object.loading = true;
      chartStates.value.object.error = null;
      try {
        const headers = getHeaders();
        const response = await axios.get(
          `${apiConfig.baseURL}/app/charts/object-analysis/${encodeURIComponent(url)}`,
          { headers }
        );
        data.value.object_analysis = response.data;
      } catch (error) {
        console.error('Error fetching object analysis:', error);
        chartStates.value.object.error = error.response?.data?.error || 'Failed to load object analysis';
        if (error.message === 'No access token found') {
          router.push('/user-login');
        }
      } finally {
        chartStates.value.object.loading = false;
      }
    }

    const fetchLastAnalyzedUrl = async () => {
      try {
        const token = localStorage.getItem('userToken');
        if (!token) {
          console.error('No JWT token found');
          return null;
        }

        const response = await axios.get(
          `${apiConfig.baseURL}/app/user/last-url`,
          { headers: { 'Authorization': `Bearer ${token}` } }
        );

        if (response.data && response.data.url) {
          lastAnalyzedUrl.value = response.data.url;
          localStorage.setItem('lastAnalyzedUrl', response.data.url);
          return response.data.url;
        }
        return null;
      } catch (err) {
        console.error('Error fetching last analyzed URL:', err);
        return null;
      }
    };

    const handleLogout = () => {
      localStorage.removeItem('userToken');
      localStorage.removeItem('username');
      localStorage.removeItem('lastAnalyzedUrl');
      window.location.href = '/user-login';
    };

    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('userToken');
        if (!token) {
          router.push('/user-login');  
          return;
        }

        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        };

        const response = await axios.get(`${apiConfig.baseURL}/app/user-details`, { headers });

        if (response.data) {
          console.log('User details:', response.data);
          user.value = response.data;
          localStorage.setItem('subscriptionStatus', response.data.subscription_status);
        } else {
          throw new Error('No user data received');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        if (error.response?.status === 401) {
          localStorage.removeItem('userToken');
          localStorage.removeItem('subscriptionStatus');
          router.push('/user-login');  
        }
        throw error;
      }
    };

    // Watch for route changes
    watch(
      () => route.query,
      async (newQuery) => {
        if (newQuery.url) {
          try {
            // Decode the URL from the query parameter
            const decodedUrl = decodeURIComponent(newQuery.url);
            currentUrl.value = decodedUrl;
            await fetchDataFromBackend(decodedUrl);
          } catch (error) {
            console.error('Error processing URL:', error);
            error.value = 'Invalid URL format';
          }
        }
      },
      { immediate: true }
    );

    // Watch for data changes
    watch(data, (newData) => {
      if (newData.tone_analysis?.data || newData.emotion_analysis?.data) {
        // Force chart update
        nextTick(() => {
          const charts = document.querySelectorAll('canvas');
          charts.forEach(canvas => {
            const chart = Chart.getChart(canvas);
            if (chart) {
              chart.update();
            }
          });
        });
      }
    }, { deep: true });

    const handleUrlSubmit = async () => {
      if (!newUrl.value) return;
      
      // Basic URL validation
      const urlPattern = /^[^/\s]+\.[^/\s]+.*$/;
      if (!urlPattern.test(newUrl.value)) {
        console.error('Invalid URL format');
        return;
      }
      
      isSubmitting.value = true;
      try {
        // Clean URL and add https if needed
        const cleanUrl = newUrl.value.replace(/^(https?:\/\/)?(www\.)?/, '');
        const fullUrl = `https://${cleanUrl}`;

        // Get current user token
        const token = localStorage.getItem('userToken');
        if (!token) {
          throw new Error('No access token found');
        }

        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };

        // Reset existing data
        data.value = {
          tone_analysis: null,
          emotion_analysis: null,
          object_analysis: null,
          style_analysis: null,
          audience_analysis: null
        };

        const response = await axios.post(
          `${apiConfig.baseURL}/app/submit-url`,
          { url: fullUrl },
          { headers }
        );

        if (response.data.redirect_url) {
          // Update URL in browser without reloading
          router.replace({ query: { ...route.query, url: encodeURIComponent(fullUrl) }});
          
          // Update current URL and fetch new data
          currentUrl.value = fullUrl;
          await fetchDataFromBackend(fullUrl);
          
          // Clear input only after successful fetch
          newUrl.value = '';
        }
      } catch (error) {
        console.error('Error submitting URL:', error);
        if (error.message === 'No access token found') {
          router.push('/user-login');
        }
      } finally {
        isSubmitting.value = false;
      }
    };

    onMounted(async () => {
      try {
        // First fetch user details
        await fetchUserDetails();
        
        // Then handle URL from route if present
        if (route.query.url) {
          currentUrl.value = decodeURIComponent(route.query.url);
          await fetchDataFromBackend(currentUrl.value);
        } else {
          // If no URL in route, try to fetch last analyzed URL
          const lastUrl = await fetchLastAnalyzedUrl();
          if (lastUrl) {
            currentUrl.value = lastUrl;
            await fetchDataFromBackend(lastUrl);
          }
        }
      } catch (error) {
        console.error('Error in component mount:', error);
        if (error.response?.status === 401) {
          router.push('/user-login');
        }
      }
    });

    // Add this computed property
    const isDevelopment = computed(() => {
      return __VUE_OPTIONS_API__;  // This is true in development mode
    });

    const formatUrl = (url) => {
      if (!url) return '';
      return url.replace(/^https?:\/\/(www\.)?/, '');
    };

    // Add these watchers to monitor data changes
    watch(() => data.value.tone_analysis, (newVal) => {
      console.log('Tone analysis updated:', newVal);
    }, { deep: true });

    watch(() => data.value.emotion_analysis, (newVal) => {
      console.log('Emotion analysis updated:', newVal);
    }, { deep: true });

    watch(() => data.value.object_analysis, (newVal) => {
      console.log('Object analysis updated:', newVal);
    }, { deep: true });

    // Watch currentUrl changes
    watch(() => currentUrl.value, async (newUrl) => {
      if (newUrl) {
        console.log('URL changed, fetching new data for:', newUrl);
        await fetchDataFromBackend(newUrl);
      }
    });

    // Watch loading state changes
    watch(() => isSubmitting.value, async (newVal, oldVal) => {
      if (oldVal === true && newVal === false) {
        // Loading just finished, ensure data is refreshed
        console.log('Loading finished, refreshing charts...');
        await nextTick();
        const charts = document.querySelectorAll('canvas');
        charts.forEach(canvas => {
          const chart = ChartJS.getChart(canvas);
          if (chart) {
            chart.update();
          }
        });
      }
    });

    return {
      data,
      error,
      loading,
      user,
      handleLogout,
      chartStates,
      handleChartError,
      toneChartData,
      emotionChartData,
      objectChartData,
      toneChartOptions,
      emotionChartOptions,
      defaultBarOptions,
      newUrl,
      isSubmitting,
      handleUrlSubmit,
      formatUrl,
    };
  }
};
</script>

<style scoped>
.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.arrow-button {
  min-width: 40px; /* Ensure consistent width */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
}

.loading-text {
  margin-top: 1rem;
  color: #333;
  font-size: 1.1rem;
}

.spinner {
  display: inline-block;
  width: 50px;  /* Bigger spinner */
  height: 50px;
  border: 3px solid #4CAF50;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.progress-bar {
  width: 100%;
  height: 4px;
  background: #eee;
  border-radius: 2px;
  margin: 1rem 0;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: #4CAF50;
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.5rem;
}

.loading-text {
  margin: 1rem 0;
  color: #333;
  font-size: 1.1rem;
}
</style>



