<template>
  <div class="landing-header">
    <router-link to="/login" class="login-page">Login</router-link>
    <router-link to="/register" class="signup-page">Signup</router-link>
  </div>
  <div class="landing-page">
    <h1 v-if="!isLoading">Scan your brand</h1>
    <form @submit.prevent="submitUrl" v-if="!isLoading">
      <input type="text" v-model="url" placeholder="Enter URL" required />
      <button type="submit" :disabled="!isValidUrl" class="arrow-button">
        ➔
      </button>
    </form>
    <LoadingWheel v-else :status="logMessage" :progress="taskProgress" />
  </div>
</template>

<script>
import axios from 'axios';
import LoadingWheel from '@/components/LoadingWheel.vue';
import api from '@/config/api';

export default {
  data() {
    return {
      url: '',
      isLoading: false,
      logMessage: '',
      taskId: null,
      taskProgress: 0,
    };
  },
  computed: {
    isValidUrl() {
      const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!this.url.match(urlPattern);
    }
  },
  methods: {
    async submitUrl() {
      if (!this.url) {
        this.logMessage = 'Please enter a URL.';
        return;
      }

      let token = localStorage.getItem('userToken');
      let isDemoMode = false;

      // If not authenticated, use demo login
      if (!token) {
        try {
          const response = await axios.post(`${api.baseURL}/app/demo-login`, {
            username: 'demo',
            email: 'demo@cp3.ai',
            password: 'demo'
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            withCredentials: false // Important for CORS
          });
          if (response.data && response.data.access_token) {
            token = response.data.access_token;
            isDemoMode = true;
            // Store the token temporarily
            localStorage.setItem('userToken', token);
          } else {
            this.logMessage = 'Error getting demo access. Please try again.';
            return;
          }
        } catch (error) {
          console.error('Error getting demo access:', error);
          this.logMessage = error.response?.data?.message || 'Error getting demo access. Please try again.';
          return;
        }
      }

      this.isLoading = true;
      this.logMessage = 'Submitting URL...';

      let formattedUrl = this.url;
      if (!formattedUrl.startsWith('http://') && !formattedUrl.startsWith('https://')) {
        formattedUrl = 'https://' + formattedUrl;
      }

      try {
        const response = await axios.post(`${api.baseURL}${api.endpoints.submitUrl}`, 
          { url: formattedUrl },
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          }
        );

        if (response.data && response.data.task_id) {
          await this.trackTask(response.data.task_id, formattedUrl, isDemoMode);
        } else {
          this.logMessage = 'Error: No task ID received.';
          this.isLoading = false;
        }
      } catch (error) {
        console.error('Error submitting URL:', error);
        if (error.response && error.response.status === 401) {
          // Token expired or invalid
          localStorage.removeItem('userToken');
          this.logMessage = 'Session expired. Please try again.';
          this.isLoading = false;
        } else {
          this.logMessage = 'Error submitting URL. Please try again.';
          this.isLoading = false;
        }
      }
    },

    async trackTask(taskId, formattedUrl, isDemoMode) {
      this.taskId = taskId;
      this.logMessage = 'Processing URL...';
      let retries = 0;
      const maxRetries = 60; // 5 minutes with 5-second intervals

      const checkStatus = async () => {
        try {
          const response = await axios.get(`${api.baseURL}/app/task/${this.taskId}/status`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('userToken')}`
            }
          });

          const status = response.data.status;
          this.taskProgress = response.data.progress || 0;

          if (status === 'completed') {
            this.isLoading = false;
            // If in demo mode, clean up the temporary token
            if (isDemoMode) {
              localStorage.removeItem('userToken');
            }
            // Wait a moment to ensure all data is saved
            await new Promise(resolve => setTimeout(resolve, 2000));
            
            try {
              // Force a hard navigation instead of using Vue Router
              window.location.href = `/app//dashboard-user?url=${encodeURIComponent(formattedUrl)}`;
            } catch (error) {
              console.error('Navigation error:', error);
              // Fallback to Vue Router if direct navigation fails
              this.$router.push({
                path: '//dashboard-user',
                query: { url: formattedUrl }
              }).catch(err => {
                console.error('Router navigation error:', err);
                // Last resort - reload the page
                window.location.reload();
              });
            }
          } else if (status === 'failed') {
            this.isLoading = false;
            this.logMessage = 'Error processing URL. Please try again.';
          } else {
            // Task still running, check again in 5 seconds
            retries++;
            if (retries < maxRetries) {
              setTimeout(checkStatus, 5000);
            } else {
              this.isLoading = false;
              this.logMessage = 'Processing timed out. Please try again.';
            }
          }
        } catch (error) {
          console.error('Error checking task status:', error);
          this.isLoading = false;
          this.logMessage = 'Error checking task status. Please try again.';
        }
      };

      // Start checking status
      await checkStatus();
    }
  },
  components: {
    LoadingWheel,
  },
};
</script>
