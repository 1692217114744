<template>
  <div class="forgot-password-container">
    <div class="form-container">
      <h2>Password Recovery</h2>
      <p v-if="message" :class="{ 'success-message': isSuccess, 'error-message': !isSuccess }">{{ message }}</p>
      <form @submit.prevent="handleSubmit" class="forgot-password-form">
        <div class="form-group">
          <label for="email"></label>
          <input 
            type="email" 
            id="email" 
            v-model="email" 
            required
            placeholder="Enter your email address"
            :disabled="loading"
          >
        </div>
        <button type="submit" :disabled="loading" class="submit-button">
          {{ loading ? 'Sending...' : 'Reset Password' }}
        </button>
        <div class="links">
          <router-link to="/user-login" class="back-to-login">Back to Login</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      loading: false,
      message: '',
      isSuccess: false
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      this.message = '';
      
      try {
        const response = await fetch(`${apiConfig.baseURL}${apiConfig.endpoints.forgotPassword}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: this.email })
        });

        const data = await response.json();
        
        if (response.ok) {
          this.isSuccess = true;
          this.message = 'Password reset instructions have been sent to your email.';
          this.email = ''; // Clear the form
        } else {
          this.isSuccess = false;
          this.message = data.error || 'Failed to process password reset request.';
        }
      } catch (error) {
        console.error('Error:', error);
        this.isSuccess = false;
        this.message = 'An error occurred. Please try again later.';
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>