<template>
  <div class="thank-you-container">
    <h1>Thank You for your interest!</h1>
    <p>We're excited to have you on board! The game is about to change, CP3 is not another tool,Stay tuned for the release and buckle up, for this will be a stellar ride!</p>
  </div>
</template>

<script>
export default {
  name: 'ThankYou'
};
</script>


