<template>
  <div class="main-container">
    <div class="flex-row-e">
      <span class="cp">CP3</span>
      <div class="profile">
        <div class="frame"><div class="outline-status-notification"></div></div>
        <input class="profile-input" />
        <div class="frame-1"></div>
      </div>
    </div>
    <div class="flex-row-e-2">
      <div class="rectangle"><div class="icon"></div></div>
      <div class="frame-3">
        <div class="flex-row-f">
          <div class="platforms-icons">
            <button class="frame-4"><span class="all">All</span></button>
            <div class="frame-5"><div class="layer"></div></div>
            <div class="frame-6">
              <div class="solid-brands-linkedin"><div class="union"></div></div>
            </div>
            <div class="frame-7">
              <div class="outline-brands-instagram">
                <div class="icon-8"></div>
              </div>
            </div>
          </div>
          <span class="advice-9">Advice</span>
        </div>
        <div class="flex-row-b">
          <div class="frame-a">
            <div class="frame-b">
              <span class="voice">Voice</span>
              <div class="frame-c">
                <div class="frame-d"><span class="slash">8/10</span></div>
                <div class="line"></div>
                <div class="frame-e">
                  <div class="frame-f"><span class="number">1</span></div>
                  <div class="frame-10"><span class="number-11">2 </span></div>
                  <span class="warnings-improvements"
                    >Warnings and improvements</span
                  >
                </div>
              </div>
            </div>
            <div class="frame-12">
              <div class="frame-13">
                <div class="frame-14">
                  <div class="frame-15">
                    <div class="frame-16"><div class="layer-17"></div></div>
                    <span class="plus">+3</span>
                  </div>
                  <span class="speak-more-aspirational"
                    >Speak more aspirational</span
                  >
                </div>
                <button class="frame-18">
                  <span class="improve">Improve</span>
                  <div class="vector"></div>
                </button>
              </div>
              <div class="frame-19">
                <div class="frame-1a">
                  <div class="frame-1b">
                    <div class="frame-1c">
                      <div class="solid-brands-linkedin-1d">
                        <div class="union-1e"></div>
                      </div>
                    </div>
                    <span class="plus-1f">+2</span>
                  </div>
                  <span class="improve-overall-writing"
                    >Improve overall writing</span
                  >
                </div>
                <button class="frame-20">
                  <span class="improve-21">Improve</span>
                  <div class="vector-22"></div>
                </button>
              </div>
              <div class="frame-23">
                <div class="frame-24">
                  <div class="frame-25">
                    <div class="frame-26">
                      <div class="outline-brands-instagram-27">
                        <div class="icon-28"></div>
                      </div>
                    </div>
                    <span class="plus-29">+1</span>
                  </div>
                  <span class="use-different-wording"
                    >Use different wording</span
                  >
                </div>
                <button class="frame-2a">
                  <span class="improve-2b">Improve</span>
                  <div class="vector-2c"></div>
                </button>
              </div>
              <div class="frame-2d">
                <div class="frame-2e">
                  <div class="frame-2f">
                    <div class="frame-30">
                      <div class="solid-brands-linkedin-31">
                        <div class="union-32"></div>
                      </div>
                    </div>
                    <span class="plus-33">+1</span>
                  </div>
                  <span class="use-different-wording-showcase-ambition"
                    >Use different wording to showcase ambition</span
                  >
                </div>
                <button class="Button-5">
                  <span class="text-14">Improve</span>
                  <div class="img-6"></div>
                </button>
              </div>
              <div class="section-e">
                <div class="box-f">
                  <div class="box-10">
                    <div class="wrapper-7">
                      <div class="group-9">
                        <div class="section-f"><div class="img-7"></div></div>
                      </div>
                      <span class="text-15">+1</span>
                    </div>
                    <span class="text-16"
                      >Use different wording to showcase confidence</span
                    >
                  </div>
                  <button class="Button-6">
                    <span class="text-17">Improved</span>
                    <div class="pic-a"></div>
                  </button>
                </div>
                <div class="wrapper-8">
                  <div class="wrapper-9">
                    <span class="text-18"
                      >Nike’s "Infinite Play" campaign highlights the brand’s
                      commitment to sustainability and circular fashion. This
                      campaign is centred around the concept of giving products
                      a second life, extending their use, and embracing a future
                      where performance meets environmental responsibility. The
                      key message is that every product can live multiple
                      lives—from creation to resale to recycling—allowing
                      consumers to play their part in shaping a more sustainable
                      future.</span
                    >
                  </div>
                </div>
                <div class="group-a">
                  <div class="wrapper-a">
                    <div class="wrapper-b">
                      <div class="group-b">
                        <div class="group-c"><div class="pic-b"></div></div>
                        <div class="group-d"><div class="img-8"></div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="group-e">
                <div class="wrapper-c">
                  <div class="group-f">
                    <div class="group-10">
                      <div class="group-11"><div class="img-9"></div></div>
                    </div>
                    <span class="text-19">+1</span>
                  </div>
                  <span class="text-1a">Sound more aspirational</span>
                </div>
                <button class="Button-7">
                  <span class="text-1b">Improve</span>
                  <div class="img-a"></div>
                </button>
              </div>
            </div>
          </div>
          <div class="group-12">
            <div class="group-13">
              <span class="text-1c">Vision</span>
              <div class="section-10">
                <div class="wrapper-d"><span class="text-1d">7/10</span></div>
                <div class="img-b"></div>
                <div class="box-11">
                  <button class="Button-8">
                    <span class="text-1e">5 </span></button
                  ><span class="text-1f">Warnings and improvements</span>
                </div>
              </div>
            </div>
            <div class="group-14">
              <div class="wrapper-e">
                <div class="box-12">
                  <div class="wrapper-f">
                    <div class="group-15"><div class="img-c"></div></div>
                    <span class="text-20">+3</span>
                  </div>
                  <span class="text-21"
                    >Make sneakers stand out more on the homepage</span
                  >
                </div>
                <button class="Button-9">
                  <span class="text-22">Improve</span>
                  <div class="pic-c"></div>
                </button>
              </div>
              <div class="group-16">
                <div class="box-13">
                  <div class="group-17">
                    <div class="group-18">
                      <div class="wrapper-10">
                        <div class="box-14"><div class="img-d"></div></div>
                      </div>
                      <span class="text-23">+3</span>
                    </div>
                    <span class="text-24"
                      >Create more content showcasing casual jackets</span
                    >
                  </div>
                  <button class="Button-a">
                    <span class="text-25">Improving...</span>
                  </button>
                </div>
                <div class="box-15">
                  <div class="box-16">
                    <div class="section-11">
                      <div class="pic-d"></div>
                      <div class="pic-e"></div>
                      <div class="img-e"></div>
                    </div>
                    <div class="wrapper-11">
                      <div class="img-f"></div>
                      <div class="pic-f"></div>
                      <div class="pic-10"></div>
                    </div>
                    <div class="flex-row-bf">
                      <div class="vector-34"></div>
                      <div class="vector-35"></div>
                    </div>
                    <div class="flex-row-edd">
                      <div class="vector-36"></div>
                      <div class="vector-37"></div>
                      <div class="vector-38"></div>
                    </div>
                    <div class="flex-row-dc">
                      <div class="vector-39"></div>
                      <div class="vector-3a"></div>
                      <div class="vector-3b"></div>
                    </div>
                    <div class="flex-row">
                      <div class="vector-3c"></div>
                      <div class="vector-3d"></div>
                      <div class="vector-3e"></div>
                    </div>
                    <div class="flex-row-cb">
                      <div class="vector-3f"></div>
                      <div class="vector-40"></div>
                      <div class="vector-41"></div>
                    </div>
                    <div class="flex-row-a">
                      <div class="vector-42"></div>
                      <div class="vector-43"></div>
                      <div class="vector-44"></div>
                    </div>
                    <div class="flex-row-a-45">
                      <div class="vector-46"></div>
                      <div class="vector-47"></div>
                      <div class="vector-48"></div>
                    </div>
                    <div class="flex-row-ae">
                      <div class="vector-49"></div>
                      <div class="vector-4a"></div>
                      <div class="vector-4b"></div>
                    </div>
                    <div class="flex-row-cc">
                      <div class="vector-4c"></div>
                      <div class="vector-4d"></div>
                      <div class="vector-4e"></div>
                    </div>
                    <div class="flex-row-4f">
                      <div class="vector-50"></div>
                      <div class="vector-51"></div>
                      <div class="vector-52"></div>
                    </div>
                    <div class="flex-row-becc">
                      <div class="vector-53"></div>
                      <div class="vector-54"></div>
                      <div class="vector-55"></div>
                    </div>
                    <div class="flex-row-d">
                      <div class="vector-56"></div>
                      <div class="vector-57"></div>
                      <div class="vector-58"></div>
                    </div>
                    <div class="flex-row-ae-59">
                      <div class="vector-5a"></div>
                      <div class="vector-5b"></div>
                      <div class="vector-5c"></div>
                    </div>
                    <div class="flex-row-c">
                      <div class="vector-5d"></div>
                      <div class="vector-5e"></div>
                    </div>
                    <div class="flex-row-d-5f">
                      <div class="vector-60"></div>
                      <div class="vector-61"></div>
                      <div class="vector-62"></div>
                    </div>
                    <div class="flex-row-c-63">
                      <div class="vector-64"></div>
                      <div class="vector-65"></div>
                      <div class="vector-66"></div>
                    </div>
                    <div class="flex-row-fd">
                      <div class="regroup">
                        <div class="vector-67"></div>
                        <div class="vector-68"></div>
                      </div>
                      <div class="vector-69"></div>
                      <div class="vector-6a"></div>
                      <div class="vector-6b"></div>
                      <div class="vector-6c"></div>
                      <div class="vector-6d"></div>
                      <div class="vector-6e"></div>
                      <div class="vector-6f"></div>
                      <div class="vector-70"></div>
                      <div class="vector-71"></div>
                      <div class="vector-72"></div>
                      <div class="vector-73"></div>
                      <div class="vector-74"></div>
                      <div class="vector-75"></div>
                      <div class="vector-76"></div>
                      <div class="vector-77"></div>
                      <div class="vector-78"></div>
                      <div class="vector-79"></div>
                      <div class="vector-7a"></div>
                      <div class="vector-7b"></div>
                      <div class="vector-7c"></div>
                      <div class="vector-7d"></div>
                      <div class="vector-7e"></div>
                      <div class="vector-7f"></div>
                      <div class="vector-80"></div>
                      <div class="vector-81"></div>
                      <div class="vector-82"></div>
                      <div class="vector-83"></div>
                      <div class="vector-84"></div>
                      <div class="vector-85"></div>
                      <div class="vector-86"></div>
                      <div class="vector-87"></div>
                      <div class="vector-88"></div>
                      <div class="vector-89"></div>
                      <div class="vector-8a"></div>
                      <div class="vector-8b"></div>
                      <div class="vector-8c"></div>
                      <div class="vector-8d"></div>
                      <div class="vector-8e"></div>
                      <div class="vector-8f"></div>
                      <div class="vector-90"></div>
                      <div class="vector-91"></div>
                      <div class="vector-92"></div>
                      <div class="vector-93"></div>
                      <div class="vector-94"></div>
                      <div class="vector-95"></div>
                      <div class="vector-96"></div>
                      <div class="vector-97"></div>
                      <div class="vector-98"></div>
                      <div class="vector-99"></div>
                      <div class="vector-9a"></div>
                    </div>
                    <div class="flex-row-f-9b">
                      <div class="vector-9c"></div>
                      <div class="vector-9d"></div>
                      <div class="vector-9e"></div>
                      <div class="vector-9f"></div>
                      <div class="vector-a0"></div>
                      <div class="vector-a1"></div>
                      <div class="vector-a2"></div>
                    </div>
                    <div class="flex-row-b-a3">
                      <div class="vector-a4"></div>
                      <div class="vector-a5"></div>
                      <div class="vector-a6"></div>
                      <div class="vector-a7"></div>
                      <div class="vector-a8"></div>
                      <div class="vector-a9"></div>
                    </div>
                    <div class="flex-row-d-aa">
                      <span class="generating-improvements"
                        >Generating<br />improvements</span
                      >
                      <div class="vector-ab"></div>
                      <div class="vector-ac"></div>
                    </div>
                    <div class="flex-row-dad">
                      <div class="vector-ad"></div>
                      <div class="vector-ae"></div>
                      <div class="vector-af"></div>
                    </div>
                    <div class="flex-row-ae-b0">
                      <div class="vector-b1"></div>
                      <div class="vector-b2"></div>
                      <div class="vector-b3"></div>
                    </div>
                    <div class="flex-row-ab">
                      <div class="vector-b4"></div>
                      <div class="vector-b5"></div>
                      <div class="vector-b6"></div>
                      <div class="vector-b7"></div>
                      <div class="vector-b8"></div>
                      <div class="vector-b9"></div>
                    </div>
                    <div class="flex-row-ba">
                      <div class="vector-bb"></div>
                      <div class="vector-bc"></div>
                      <div class="vector-bd"></div>
                      <div class="vector-be"></div>
                      <div class="vector-bf"></div>
                      <div class="vector-c0"></div>
                      <div class="vector-c1"></div>
                      <div class="vector-c2"></div>
                      <div class="vector-c3"></div>
                      <div class="vector-c4"></div>
                      <div class="vector-c5"></div>
                      <div class="vector-c6"></div>
                      <div class="vector-c7"></div>
                      <div class="vector-c8"></div>
                      <div class="vector-c9"></div>
                      <div class="vector-ca"></div>
                      <div class="vector-cb"></div>
                      <div class="vector-cc"></div>
                      <div class="vector-cd"></div>
                      <div class="vector-ce"></div>
                      <div class="vector-cf"></div>
                      <div class="vector-d0"></div>
                      <div class="vector-d1"></div>
                      <div class="vector-d2"></div>
                      <div class="vector-d3"></div>
                      <div class="vector-d4"></div>
                      <div class="vector-d5"></div>
                      <div class="vector-d6"></div>
                      <div class="vector-d7"></div>
                      <div class="vector-d8"></div>
                      <div class="vector-d9"></div>
                      <div class="vector-da"></div>
                      <div class="vector-db"></div>
                      <div class="vector-dc"></div>
                      <div class="vector-dd"></div>
                      <div class="vector-de"></div>
                      <div class="vector-df"></div>
                      <div class="vector-e0"></div>
                      <div class="vector-e1"></div>
                      <div class="vector-e2"></div>
                      <div class="vector-e3"></div>
                      <div class="vector-e4"></div>
                      <div class="vector-e5"></div>
                      <div class="vector-e6"></div>
                      <div class="vector-e7"></div>
                      <div class="vector-e8"></div>
                      <div class="vector-e9"></div>
                      <div class="vector-ea"></div>
                      <div class="vector-eb"></div>
                      <div class="vector-ec"></div>
                      <div class="vector-ed"></div>
                      <div class="vector-ee"></div>
                    </div>
                    <div class="flex-row-ef">
                      <div class="vector-f0"></div>
                      <div class="vector-f1"></div>
                      <div class="vector-f2"></div>
                      <div class="vector-f3"></div>
                      <div class="vector-f4"></div>
                      <div class="vector-f5"></div>
                      <div class="vector-f6"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="frame-f7">
                <div class="frame-f8">
                  <div class="frame-f9">
                    <div class="frame-fa">
                      <div class="outline-brands-instagram-fb">
                        <div class="icon-fc"></div>
                      </div>
                    </div>
                    <span class="plus-fd">+2</span>
                  </div>
                  <span class="create-running-club"
                    >Create Running-club content in combination with sport
                    sneakers</span
                  >
                </div>
                <button class="frame-fe">
                  <span class="improve-ff">Improve</span>
                  <div class="vector-100"></div>
                </button>
              </div>
              <div class="frame-101">
                <div class="frame-102">
                  <div class="frame-103">
                    <div class="frame-104">
                      <div class="solid-brands-linkedin-105">
                        <div class="union-106"></div>
                      </div>
                    </div>
                    <span class="plus-107">+1</span>
                  </div>
                  <span class="align-sale-campaign"
                    >Align sale campaign more with artdirection</span
                  >
                </div>
                <button class="frame-108">
                  <span class="improve-109">Improve</span>
                  <div class="vector-10a"></div>
                </button>
              </div>
            </div>
          </div>
          <div class="frame-10b">
            <div class="frame-10c">
              <span class="trends">TRENDS</span>
              <div class="frame-10d">
                <div class="frame-10e"><span class="slash-10f">7/10</span></div>
                <div class="line-110"></div>
                <div class="wrapper-19">
                  <div class="section-1f"><span class="text-2f">2</span></div>
                  <span class="text-30">Warnings and improvements</span>
                </div>
              </div>
            </div>
            <div class="box-20">
              <div class="group-24">
                <div class="box-21">
                  <div class="section-20">
                    <div class="group-25"><div class="img-6a"></div></div>
                    <span class="text-31">+3</span>
                  </div>
                  <span class="text-32"
                    >Showcase Core-tex products more on the website</span
                  >
                </div>
                <button class="Button-d">
                  <span class="text-33">Improved</span>
                  <div class="img-6b"></div>
                </button>
              </div>
              <div class="section-21">
                <div class="group-26">
                  <div class="group-27">
                    <div class="section-22">
                      <div class="box-22"><div class="pic-74"></div></div>
                    </div>
                    <span class="text-34">+2</span>
                  </div>
                  <span class="text-35"
                    >Present yourself as an industry leader in
                    sustainability</span
                  >
                </div>
                <button class="Button-e">
                  <span class="text-36">Improve</span>
                  <div class="pic-75"></div>
                </button>
              </div>
              <div class="wrapper-1a">
                <div class="box-23">
                  <div class="group-28">
                    <div class="box-24"><div class="pic-76"></div></div>
                    <span class="text-37">+2</span>
                  </div>
                  <span class="text-38"
                    >Create a landing-page for the running community about
                    running-glasses</span
                  >
                </div>
                <button class="Button-f">
                  <span class="text-39">Improve</span>
                  <div class="img-6c"></div>
                </button>
              </div>
              <div class="box-25">
                <div class="wrapper-1b">
                  <div class="box-26">
                    <div class="wrapper-1c">
                      <div class="section-23"><div class="img-6d"></div></div>
                    </div>
                    <span class="text-3a">+1</span>
                  </div>
                  <span class="text-3b"
                    >Jump in on trail-vest comparison content</span
                  >
                </div>
                <button class="Button-10">
                  <span class="text-3c">Improve</span>
                  <div class="img-6e"></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-27"><div class="pic-77"></div></div>
      <div class="wrapper-1d"><div class="img-6f"></div></div>
      <div class="wrapper-1e"><div class="img-70"></div></div>
      <div class="group-29">
        <div class="group-2a"><div class="img-71"></div></div>
      </div>
      <div class="box-28"><div class="pic-78"></div></div>
      <div class="group-2b"><div class="img-72"></div></div>
    </div>
  </div><SideBar />
</template>
<script>
import SideBar from '@/components/SideBar.vue'
export default {
  name: 'AdviceView',
  components: {
    SideBar
  },
  data() {
    return {
      sidebarIcons: [
        { src: 'icon1.png', alt: 'Icon 1' },
        { src: 'icon2.png', alt: 'Icon 2' },
        { src: 'icon3.png', alt: 'Icon 3' },
        { src: 'icon4.png', alt: 'Icon 4' },
        { src: 'icon5.png', alt: 'Icon 5' }
      ],
      userProfileImage: 'profile.png',
      scoreData: [
        {
          title: 'Voice',
          gaugeData: {
            value: 8.0,
            min: 0,
            max: 10
          },
          improvementValue: '20%',
          improvements: [
            { text: 'Images on website look too stocky' },
            { text: "Models' age doesn't match your target audience" },
            { text: 'Improvement 03' }
          ]
        },
        {
          title: 'Vision',
          gaugeData: {
            value: 6.5,
            min: 0,
            max: 10
          },
          improvementValue: '3.5',
          improvements: [
            { text: 'Images on website look too stocky' },
            { text: "Models' age doesn't match your target audience" },
            { text: 'Improvement 03' }
          ]
        }
      ],
      opportunities: {
        title: '3 opportunities',
        items: [
          { name: 'Olympics', metrics: '459K +2400%', image: 'olympics.png' },
          { name: 'Unisex', metrics: '208K +1200%', image: 'unisex.png' },
          { name: 'Trail Vest', metrics: '148K +3600%', image: 'trail_vest.png' }
        ]
      }
    }
  }
}
</script>
