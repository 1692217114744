<template>
  <div class="login-container">
    <div class="login-view">
      <h2>Login</h2>
      <form @submit.prevent="handleLogin">
        <div>
          <label for="username">Username:</label>
          <input v-model="username" type="text" id="username" required />
        </div>
        <div>
          <label for="password">Password:</label>
          <input v-model="password" type="password" id="password" required />
        </div>
        <div class="form-actions">
          <button type="submit">Login</button>
          <router-link to="/forgot-password" class="forgot-password">Forgot Password?</router-link>
        </div>
      </form>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import apiConfig from '../config/api';

export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      successMessage: ''
    };
  },
  methods: {
    async handleLogin() {
      try {
        // Clear any existing messages
        this.errorMessage = '';
        this.successMessage = '';

        // Validate input
        if (!this.username || !this.password) {
          this.errorMessage = 'Please enter both username and password';
          return;
        }

        const response = await axios.post(`${apiConfig.baseURL}${apiConfig.endpoints.login}`, {
          username: this.username,
          password: this.password
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });

        if (response.data.access_token) {
          // Store token and user info
          localStorage.setItem('userToken', response.data.access_token);
          localStorage.setItem('username', this.username);
          
          // Redirect to dashboard
          this.$router.push({ name: 'DashboardUser' });
        } else {
          this.errorMessage = 'Login failed. Please try again.';
        }
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.message || 'Invalid username or password';
        } else {
          this.errorMessage = 'An error occurred during login. Please try again.';
        }
        console.error('Login error:', error);
      }
    },

    async handleForgotPassword() {
      try {
        // Clear any existing messages
        this.errorMessage = '';
        this.successMessage = '';

        if (!this.username) {
          this.errorMessage = 'Please enter your username/email to reset password';
          return;
        }

        const response = await axios.post(`${apiConfig.baseURL}${apiConfig.endpoints.forgotPassword}`, {
          email: this.username
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });

        if (response.data.success) {
          this.successMessage = 'Password reset instructions have been sent to your email.';
        } else {
          this.errorMessage = 'Failed to request password reset. Please try again.';
        }
      } catch (error) {
        this.errorMessage = 'An error occurred. Please try again later.';
        console.error('Password reset error:', error);
      }
    }
  }
};
</script>

<style>
/* Styles moved to CP3.css */
</style>
