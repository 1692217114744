<template>
  <div class="sidebar">
    <nav>
      <ul>
        <li v-for="item in menuItems" :key="item.name" @click="navigate(item.route)">
          <i :class="item.icon"></i>
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        { name: 'Advice', route: '/dashboard-advice', icon: 'fas fa-lightbulb' },
        { name: 'Campaign', route: '/dashboard-campaign', icon: 'fas fa-bullhorn' },
        { name: 'Trends', route: '/dashboard-trends', icon: 'fas fa-chart-line' },
        { name: 'User', route: '//dashboard-user', icon: 'fas fa-user' },
        { name: 'Vision', route: '/dashboard-vision', icon: 'fas fa-eye' },
        { name: 'Voice', route: '/dashboard-voice', icon: 'fas fa-microphone' },
      ]
    };
  },
  methods: {
    navigate(route) {
      this.$router.push(route);
    }
  }
};
</script>

<style scoped>
.sidebar {
  width: 50px;
  height: 100vh;
  background-color: #0a051f;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
}
nav ul {
  list-style: none;
  padding: 0;
}
nav li {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  width: 50px;
  font-size: 10px;
}
nav li:hover {
  background-color: #272255;
}
i {
  margin-right: 10px;
  font-size: 10px;

}
</style>
