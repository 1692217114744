<template>
  <div class="trend-dashboard">
    <div class="sidebar">
      <button v-for="(icon, index) in sidebarIcons" :key="index" class="sidebar-icon">
        <img :src="icon.src" :alt="icon.alt" />
      </button>
    </div>
    <div class="content">
      <div class="header">
        <h1>Trends</h1>
        <div class="trend-count">
          <span>{{ marketTrends.length }} MARKET TRENDS</span>
          <span>{{ fashionTrends.length }} FASHION TRENDS</span>
        </div>
        <div class="profile">
          <img :src="userProfileImage" alt="User Profile" class="profile-pic" />
        </div>
      </div>
      <div class="trends-section">
        <div class="trends-column" v-for="(trends, category) in trendCategories" :key="category">
          <h2>{{ category }}</h2>
          <div v-for="trend in trends" :key="trend.name" class="trend-item">
            <img :src="trend.image" alt="trend.name" />
            <div>
              <span class="trend-name">{{ trend.name }}</span>
              <span class="trend-metrics">{{ trend.metrics }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="trend-analysis">
        <h3>Trend Analysis</h3>
        <grafana-chart :type="'line'" :data="trendData" />
      </div>
      <div class="trend-proportions">
        <h3>Trend Proportions</h3>
        <grafana-chart :type="'pie'" :data="proportionData" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrendsView',
  data() {
    return {
      sidebarIcons: [
        { src: 'icon1.png', alt: 'Icon 1' },
        { src: 'icon2.png', alt: 'Icon 2' },
        { src: 'icon3.png', alt: 'Icon 3' },
        { src: 'icon4.png', alt: 'Icon 4' },
        { src: 'icon5.png', alt: 'Icon 5' }
      ],
      userProfileImage: 'profile.png',
      trendCategories: {
        'Market Trends': [
          { name: 'Olympics', metrics: '459K +2400%', image: 'olympics.png' },
          { name: 'Paris', metrics: '125K +1300%', image: 'paris.png' },
          { name: 'Community', metrics: '114.5K +5000%', image: 'community.png' }
        ],
        'Fashion Trends': [
          { name: 'Unisex', metrics: '208K +1200%', image: 'unisex.png' },
          { name: 'Gorpcore', metrics: '201K +600%', image: 'gorpcore.png' },
          { name: 'Techwear', metrics: '90K +6200%', image: 'techwear.png' }
        ],
        'Product Trends': [
          { name: 'Trail Vest', metrics: '148K +3600%', image: 'trail_vest.png' },
          { name: 'Gore-Tex', metrics: '201K +490%', image: 'gore_tex.png' },
          { name: 'Running Glasses', metrics: '107K +1200%', image: 'running_glasses.png' }
        ]
      },
      trendData: [
        { name: 'Olympics', data: [/* example data */] },
        { name: 'Unisex', data: [/* example data */] },
        { name: 'Trail Vest', data: [/* example data */] }
      ],
      proportionData: [
        { name: 'Olympics', value: 0.36 },
        { name: 'Gore-Tex', value: 0.25 },
        { name: 'Trail Vest', value: 0.15 }
      ]
    }
  }
}
</script>

<style scoped>
.trendsdashboard {
  text-align: center;
}
</style>
