<template>
  <div class="loading-container">
    <div class="spinner-ring">
      <div class="spinner-line" style="--i: 0;"></div>
      <div class="spinner-line" style="--i: 1;"></div>
      <div class="spinner-line" style="--i: 2;"></div>
      <div class="spinner-line" style="--i: 3;"></div>
      <div class="spinner-line" style="--i: 4;"></div>
      <div class="spinner-line" style="--i: 5;"></div>
      <div class="spinner-line" style="--i: 6;"></div>
      <div class="spinner-line" style="--i: 7;"></div>
      <div class="spinner-line" style="--i: 8;"></div>
      <div class="spinner-line" style="--i: 9;"></div>
      <div class="spinner-line" style="--i: 10;"></div>
      <div class="spinner-line" style="--i: 11;"></div>
      <div class="spinner-line" style="--i: 12;"></div>
      <div class="spinner-line" style="--i: 13;"></div>
      <div class="spinner-line" style="--i: 14;"></div>
      <div class="spinner-line" style="--i: 15;"></div>
      <div class="spinner-line" style="--i: 16;"></div>
      <div class="spinner-line" style="--i: 17;"></div>
      <div class="spinner-line" style="--i: 18;"></div>
      <div class="spinner-line" style="--i: 19;"></div>
      <div class="spinner-line" style="--i: 20;"></div>
      <div class="spinner-line" style="--i: 21;"></div>
      <div class="spinner-line" style="--i: 22;"></div>
      <div class="spinner-line" style="--i: 23;"></div>
      <div class="spinner-line" style="--i: 24;"></div>
      <div class="spinner-line" style="--i: 25;"></div>
      <div class="spinner-line" style="--i: 26;"></div>
      <div class="spinner-line" style="--i: 27;"></div>
      <div class="spinner-line" style="--i: 28;"></div>
      <div class="spinner-line" style="--i: 29;"></div>
    </div>
    <div class="loading-text">
      Analysing
    </div>
    <p>{{ status }}</p>
    <div class="progress-bar">
      <div class="progress" :style="{ width: progress + '%' }"></div>
    </div>
 </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>


